* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Poppins, sans-serif;
}

:root {
  --transition: 400ms;
  --lpColor: #e99a05;
  --lpBgColor: #e99a05;
  --lpBorderColor: #e99a05;
}

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem;
    gap: 1rem;

    h5 {
      text-align: center;
      font-weight: bold;
    }

    input,
    button {
      padding: 0.5rem;
      outline: none;
      border: 1px solid #bf7e05;
    }

    button {
      color: #fff;
      background-color: #bf7e05;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: #de9204;
    }
  }
}

.contener {
  padding: 0 5rem;
}

.navba {
  background-color: #000000e2;
  display: flex;
  padding: 0.1rem 13.7rem 0rem;
  justify-content: space-between;
  align-items: center;

  .user {
    color: #fff;
    font-weight: 650;
    display: flex;
    align-items: center;
    height: 100%;

    p {
      margin-top: 1rem;
    }
  }

  .menu {
    margin-top: 1rem;
  }
  .user,
  .menu {
    display: flex;
    gap: 1rem;

    li {
      list-style: none;
    }

    a {
      text-decoration: none;
      color: #fff;
      font-weight: 600;
    }

    .bouton {
      background-color: #000000;
      padding: 0.5rem;
      border: 2px solid transparent;
      cursor: pointer;
      border-radius: 0.5rem;
      color: #fff;
      transition: var(--transition);
      border: 2px #fff solid;
    }

    .bouton:hover {
      background-color: #fff;
      color: #000000;
    }
  }
  .logo {
    img {
      width: 120px;
      height: 80px;
    }
  }
}

#head {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #9dd9f5;

  .list-com {
    padding: 2rem;
    font-size: 20px;
    font-weight: 650;
  }

  a {
    text-decoration: none;
    color: var(--lpColor);
    background-color: white;
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid var(--lpBorderColor);
    // font-weight: 650;
    transition: var(--transition);
  }
}

#elmtsCde {
  display: flex;
  padding: 5rem;
  justify-content: center;
  width: 100vw;
  height: 88vh;
  gap: 5rem;

  .cde {
    overflow-y: scroll;
    height: 310px;
  }

  table {
    width: 500px;
  }
  .formModalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 520px;
      height: 400px;
      background-color: #fff;
      padding: 0rem 2rem;
      animation: fadeModalIn 400ms;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      input,
      select {
        width: 100%;
        padding: 0.3rem;
        margin-bottom: 1rem;
        border: 1px solid;
        outline: none;
      }

      .produits {
        display: grid;
        grid-template-columns: 50% 40%;
        justify-content: space-between;
        gap: 1rem;
      }

      span {
        background-color: #04216a;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem 0.5rem;
        color: #fff;
        border-radius: 4px;
        // font-size: 24px;
        cursor: pointer;
      }
    }

    .btns {
      display: grid;
      grid-template-columns: 65% 28%;
      gap: 2rem;
      justify-content: space-between;
    }
  }

  button {
    padding: 0.5rem;
    background-color: var(--lpBgColor);
    border: var(--lpBorderColor);
    color: #fff;
    width: 100%;
    font-size: 16px;
  }

  .calcul {
    display: flex;
    justify-content: space-around;

    h4 {
      font-weight: 650;
    }
  }
}

#Commande {
  height: 88vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .produit {
    display: flex;
    justify-content: space-between;
  }

  .form {
    padding: 1rem;
    margin: 1rem 0;
    gap: 3rem;
    // padding-bottom: 1rem;
    width: 550px;

    .head {
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: #404040 1px solid;
        padding: 0.5rem;
      }
    }

    h5 {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
    }

    input,
    select {
      border: 1px solid var(--lpBorderColor);
      padding: 0.5rem;
      outline: none;
    }

    button {
      border: 2px var(--lpBorderColor) solid;
      background-color: var(--lpBgColor);
      padding: 0.5rem;
      color: #fff;
    }

    .body {
      margin-top: 1rem;
      .calcul {
        display: flex;
        justify-content: space-between;
        h3 {
          font-family: "Titillium Web";
        }
      }

      .enregCommande {
        // width: 100%;
        font-weight: 650;
      }
    }
  }

  .commande {
    overflow-y: scroll;
  }
}

#cdesNulles {
  padding: 0 13.8rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  .cde {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #d1820c;
    color: #fff;
    width: 200px;
    border-radius: 0.5rem;
    margin-bottom: 2rem;

    .btnAjoutCde {
      border: none;
      padding: 0.3rem;
      border-radius: 0.4rem;
      font-weight: 650;
      background-color: #fff;
    }

    a {
      text-decoration: none;
    }
  }
}
#modalAjoutCommade {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 25;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: 300ms;

  .form-wrapper {
    form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding: 1rem;
      background-color: #fff;
      height: 300px;
      width: 400px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      animation: fadeModalIn 300ms;
      border-radius: 0.3rem;
      transition: var(--transition);

      select,
      button {
        width: 100%;
        padding: 7px;
        outline: none;
        border: 1px solid;
      }
      .head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          background-color: var(--lpBgColor);
          color: #fff;
          font-size: 20px;
          padding: 0rem 0.4rem;
          border-radius: 0.3rem;
          cursor: pointer;
        }

        h5 {
          margin-top: 5px;
        }
      }
      button {
        background-color: var(--lpBgColor);
        color: white;
      }
    }
  }
}
#ajoutCommande {
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 15rem;
  padding-top: 5rem;
  gap: 5rem;
  height: 88vh;

  .head {
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      // border: #a9a5a5 1px solid;
      padding: 0.5rem;

      input,
      select,
      button {
        border: 1px solid;
        padding: 0.3rem;
      }
      button {
        background-color: var(--lpBgColor);
        border: 1px solid transparent;
      }
    }
  }
}

#Accueil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 14.2rem;
  height: 88vh;
  .clientsCdes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    .clients {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
      background-color: #02073cda;
      color: #fff;
      padding: 1.3rem 0.5rem;
      border-radius: 1rem;
      height: 200px;
      text-align: center;
      box-shadow: rgba(1, 14, 79, 0.35) 0px 5px 15px;
    }
  }
}

#AccueilAdmin {
  padding: 0 14.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 88vh;

  .cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;

    .utilisateurs,
    .produits {
      background-color: #04216a;
      color: #fff;
      padding: 3rem 2rem;
      border-radius: 1.5rem;
    }

    .gauche,
    .droite {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 3rem;

      .client {
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: space-between;
        text-align: center;
        gap: 1rem;

        .clients {
          background-color: #04216a;
          width: 100%;
          padding: 1rem 2rem;
          width: 250px;
          border-radius: 0.7rem;
        }
      }
    }

    //   .produits,
    //   .utilisateurs {
    //     background-color: #04216a;
    //     color: #fff;
    //     width: 500px;
    //     height: 200px;
    //     text-align: center;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     padding: 2rem;
    //     margin: 3rem 0;
    //     border-radius: 1rem;
    //   }
    // }

    // .client {
    //   width: 100%;
    //   display: flex;
    //   justify-content: space-between;
    //   gap: 2rem;

    //   .clients {
    //     width: 200px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     padding: 1rem;
    //     background-color: #04216a;
    //     color: #fff;
    //     text-align: center;
    //     border-radius: 1rem;
    //   }
  }
}

.controlsBtn {
  justify-content: flex-start;
  display: flex;
  gap: 0.7rem;
}

#AffichClient {
  display: flex;
  height: 88dvh;
  justify-content: center;
  align-items: center;

  .client {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 500px;
    height: 400px;
    box-shadow: rgba(1, 14, 79, 0.35) 0px 5px 15px;
    border-radius: 0.5rem;

    .separ,
    .labels {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
    }

    p {
      font-weight: 600;
    }
  }
}

#ajoutClient {
  display: flex;
  height: 88vh;
  justify-content: center;
  align-items: center;

  form {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h5 {
      font-weight: 700;
    }
    input,
    select {
      border: #e99a05 1px solid;
      padding: 0.4rem;
      outline: none;
    }

    button {
      border: #e99a05 1px solid;
      background-color: #e99a05;
      padding: 0.5rem;
      color: #fff;
      font-size: 18px;
      font-weight: 550;
    }
  }
}

#introuvable {
  display: flex;
  flex-direction: column;
  background-color: #04216a;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100vh;

  h1 {
    font-size: 155px;
    font-weight: 800;
  }

  p {
    font-size: 75px;
  }
}

@keyframes fadeModalIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.controls {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  button {
    background-color: transparent;
    border: transparent;
  }
  span {
    color: #086dd2;
    font-weight: 600;
  }
}
